@import "site/sass-mixins.scss";

#landing-content, #localweb-content {

    .page-heading {
        h2 {
            margin-top: 0;
            @media (min-width: 550px) {
                margin-top: 0.3em;
            }
            @media (min-width: 1024px) {
                margin-top: 0.5em;
            }
        }
    }

    .icon-block-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media (max-width: 649px) {
            flex-direction: column;
        }
        justify-content: space-between;
        // background-color: aqua;
    
        .icon-block {
            flex: 0 0 30%;
            margin-top: 15px;
            @media (min-width: 650px) {
                order:1;
                margin-top: 0;
            }
            display: flex;
            flex-direction: row;
            justify-content: center;
            // align-content: flex-start;
            align-items: center;
            line-height: 0;
            svg {
                width: 70px;
            }
            .ps-gauge {
                position: relative;
                svg {
                    width: 70px;
                    .lh-gauge-base {
                        fill: rgb(12, 206, 107);
                        opacity: 0.1;
                    }
                    .lh-gauge-arc {
                        stroke: rgb(12, 206, 107);
                        fill: none;
                        stroke-width: 8px;
                    }
                }
                p {
                    position: absolute;
                    margin: 0;
                    padding: 0;
                    top: 0;
                    line-height: 70px;
                    color: rgb(12, 206, 107);
                    font-family: monospace;
                    font-size: 28px;
                    left: 0;
                    width: 70px;
                    text-align: center;
                }
            }
            h2.action-button {
                line-height: 1em;
                margin: 0;
                @media (max-width: 649px) {
                    margin-bottom: 5px;
                }
                border: 3px solid black;
                padding: 1px 10px 3px 10px;
                border-radius: 5px;
            }
            
            svg.fastpage {
                path, line, ellipse {
                    fill:none;
                    stroke:#505050;
                    stroke-width:4.25px;
                }
                .icon-color-1 {
                    fill: #f28b32;
                }
                .icon-color-2 {
                    fill: yellow;
                }

                .round-end {
                    stroke-linecap:round;
                }
            }

            .sterling-icon svg.sterling {
                width: 50px;
                path {
                    fill: #505050;
                    stroke:none;
                    // stroke-width:4.25px;
                }
            }
            .location-icon svg.location {
                width: 50px;
                path {
                    fill: #505050;
                    stroke:none;
                    // stroke-width:4.25px;
                }
            }
        }

        .icon-block-heading {
            flex: 0 0 30%;
            @media (min-width: 650px) {
                order: 2;
            }
            h2 {
                margin: 5px 0 5px 0;
                text-align: center;
            }
        }
        .icon-block-content {
            flex: 0 0 30%;
            @media (min-width: 650px) {
                order: 3;
            }
            @media (min-width: 500px) and (max-width: 649px) {
                width: 75%;
            }
            align-self: flex-start;
            p {
                margin: 0;
                color:#333;
                text-align: center;
                padding-bottom: 10px;
                font-size: 1.2em;
                font-weight: 300;
                @include ipad2 {
                    font-size: 1.2em;
                    font-weight: 400;
                }
            }
        }
    }

    .action-text {
        p {
            font-size: 1.1em;
            font-weight: 300;
            @include ipad2 {
                font-size: 1.2em;
                font-weight: 400;
            }
            margin-bottom: 0;
        }
    }

    form  {
        position: relative;
        top: -10px;
    }
}


