// @mixin ipad2 { // and ipad1
//     @media screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 1) {
//         @content;
//     }
// }

@import "site/sass-mixins.scss";

#footerwrapper {
    // @media screen and (max-width: 400px) {
    //     display: none;
    // }
    // height: 100px;
    color: white;

    @include fontFooter(300);
    // font-family: 'Roboto', sans-serif;
    // font-weight: 600; // Assume retina display
    // @media (-webkit-min-device-pixel-ratio: 2) { 
    //     font-weight: 300;
    // }
    font-size: 1rem;

    background-color: #000;
    background: linear-gradient(to bottom, #000, #333);

    .footer-content {

        max-width: 1200px;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;

        font-size: 1.1em;
        @include footerWeight(300);
        @include ipad2 {
            font-size: 1.2em;
            @include footerWeight(400);
        }

        display: flex;
        flex-direction: column;
        @media screen and (min-width: 600px) {
            flex-direction: row;
        }


        justify-content: space-around;

        color: $footertextcolor;
        a {

            text-decoration: none;
            &, &:visited {
                color:$footertextcolor;
            }
            @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
                &:hover {
                    color: $highlight
                }
              }

        }

        .contact-icon {
            width: 35px;
            height: 35px;
            padding-right: 7px;
        }
        // .footer-logo-link {
        //     flex: 0 4 auto;
        //     padding-right: 20px;
        //     max-width: 200px;
        //     .footer-logo {

        //         width: 100%;
        //         padding-right: 20px;
        //         // .text-app {
        //         //     fill: #eee;
        //         // }
        //         // .text-zuka {
        //         //     fill: $highlight
        //         // }
        //     }
        // }


        .footer-logo-link {

            flex: 1 1 auto;
            justify-content: space-around;
    
            .footer-logo {
                flex: 0 1 auto;
                h2 {
                    font-family: $font-logo;
                    font-weight: 100;
                    color: $footertextcolor;
                    margin: 0;
                    font-size: 2.1em;
                }
        
            }
            @media screen and (max-width: 599px) {
                max-width: initial; //
                // outline: 1px solid green;
                .footer-logo {
                    // outline: 1px solid white;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding-bottom: 0.5em;
                    h2 {
                        // outline: 1px solid red;
                        padding-left: 0.3em;
                        &:first-child {
                            padding-left: 0;
                        }
                        @media screen and (max-width: 380px) {
                            font-size: 1.8em;
                        }
                    }
                }
            }
        }

        .footer-address {
            flex: 1 2 auto;
            padding-right: 20px;
            .footer-contact-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 7px;
                .contact-icon {
                    flex: 0 1 auto;
                    fill: $footertextcolor;
                }
                p {
                    flex: 0 1 auto;

                    margin: 0;
                }
            }
        }
        .footer-contacts {
            flex: 1 0 auto;
            .footer-contact-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 7px;
                .contact-icon {
                    flex: 0 1 auto;
                    fill: $footertextcolor;
                }
                p {
                    flex: 0 1 auto;
                    margin: 0;
                }

            }
        }
    }
    .footer-copyright-wrapper {
        background-color: black;
        color: $footertextcolor;
        font-size: 0.9em;
        @include footerWeight(300);
        @media screen and (min-width: 1023px) {
            @include ipad2 {
                font-size: 1.1em;
                @include footerWeight(400);
            }
        }


        // font-size: 0.9rem;
        // @media (-webkit-min-device-pixel-ratio: 2) { 
        //     font-size: 0.8rem;
        // }
        .footer-copyright {
            max-width: 1200px;
            margin: auto;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 760px) {
                flex-direction: row;
            }
            justify-content: space-between;
            a {
                text-decoration: none;
                &, &:visited {
                    color:$footertextcolor;
                }
                &:hover {
                    color:$hover;
                }
            }
            .terms-links {
                // display: inline-block;
                padding-bottom: 10px;

                @media screen and (min-width: 760px) {
                    padding-bottom: 0;
                }
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        line-height: 1em;
                        display: inline-block;
                        padding-right: 10px;
                        padding-left: 10px;
                        border-left: 1px solid $footertextcolor;
                        &:first-child {
                            border-left: none;
                            padding-left: 0;
                        }

                    }
                }
            }
            .terms-copyright {
                // display: inline-block;
                p {
                    margin: 0;
                    a {
                        color: $appzuka;
                        &:hover {
                            color: $appzuka;
                        }
                    }
                }
            }
        }

    }
}

#footer-versions {
    background-color: grey;
    display: none;
    ul {
        margin: 0;
        padding-left: 50px;
        li {
            list-style-type: none;
            display: inline-block;
            p {
                margin: 0;
                padding-right: 10px;
            }
        }
    }
}