@import "site/sass-mixins.scss";


.skills-list {

    .consulting-blocks {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .home-skill-block-link {
            &, :visited, :hover {
                text-decoration: none
            }

        }

        .home-skill-block {

            flex: 0 0 14%;
            // min-width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            h2 {
                font-family: 'Open Sans', sans-serif;
                color: #757575;
                font-size: 1.1em;
                font-weight: 300;
                text-align: center;
                max-width: 100px;
            }
            svg.tech-icon, img.tech-icon {
                height: 60px; 
            }
            svg { // Remove once all svgs converted to use incdom loader
                height: 60px; 
            }
        }

        @media (max-width: 1023px) {
            .home-skill-block-0, .home-skill-block-1, .home-skill-block-2, .home-skill-block-3 {
                flex: 0 0 25%;
            } 
            .home-skill-block-4, .home-skill-block-6 {
                flex: 1 0 44%;
            } 
            .home-skill-block-5 {
                flex: 0 0 10%;
            } 
        }

        @media (max-width: 599px) {
            .home-skill-block-0, .home-skill-block-1, .home-skill-block-2, .home-skill-block-3, .home-skill-block-4, .home-skill-block-5 {
                flex: 0 0 33%;
            } 
            .home-skill-block-6 {
                flex: 1 0 100%;
            } 
        }

        &.consulting-blocks-2 {
            .home-skill-block-0, .home-skill-block-1 {
                flex: 1 0 50%;
            } 
        }

        &.consulting-blocks-3 {
            .home-skill-block-0, .home-skill-block-1, .home-skill-block-2 {
                flex: 1 0 33%;
            } 
            @media (min-width: 767px) {
                .home-skill-block-0, .home-skill-block-2 {
                    flex: 1 0 40%;
                } 
                .home-skill-block-1 {
                    flex: 0 0 10%;
                } 
            } 
        }

    }


}
    
