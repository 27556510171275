@import "site/sass-mixins.scss";

// Styling applies both for the headmenu and scroll menu
// Styling for the scrollmenu is denoted by '#topmenuwrapper &'

#headmenuwrapper {
    position: relative;
    width: 100%;
    font-size: 1rem;
}

#topmenuwrapper {
    top: 0;
    z-index: 1000;
    position: fixed;
    width: 100%;
}

.topmenuouter {

    .topmenubar {

        position: relative;
        top: 0px;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .menubarlogolink {
            text-decoration: none;
            flex: 1 0 auto;
            #topmenuwrapper & { // Only applies to the logo in the scroll menu, not the scroll menu
                flex: 0 0 auto;
                padding-right: 20px;
            }
            margin-left: 10px;

            svg { // If the contents of the logo div is an SVG set its height;
                height: 27px;
            }
            .header-logo { // If the logo is a text

                margin-top: 0;
                margin-bottom: 0;
                font-family: $font-logo;
                font-weight: 100;
                font-size: 1.7em;
                font-weight: 300;
        
                @media screen and (min-width: 500px) {
                    font-size: 2.5em;
                    font-weight: 100;
                }
        
                color: $menucontentcolor;
                &:hover {
                    color:$menucontentcolor;
                }

                #topmenuwrapper & { // Only applies to the logo in the scroll menu, not the scroll menu
                    color: $scrollmenucontentcolor;
                    &:hover {
                        color: $scrollmenucontentcolor;
                    }
                    padding-left: 20px;
                    font-size: 1.6em;
                }
        
            }

        }

        .content {
            flex: 0 1 auto;
            overflow-x: hidden;
            margin-left: 0;
            #topmenuwrapper & {
                flex: 1 1 auto;
                margin: 0;
                ul {
                    @media screen and (max-width: 650px) {
                        display: none;
                    }
                    justify-content: space-around;
                    margin: 0;
                    li {
                        font-size: 0.9em;
                        padding-left: 7px;
                        padding-right: 7px;
                    }
                }
            }
        }

        .hamburger-icon {
            flex: 0 0 20px;
            height: 20px;
            fill: $hamburgercolor;
            padding: 5px;
            margin: 5px;
            @media screen and (min-width: 768px) {
                margin: 10px;
            }
            border: 1px solid $hamburgercolor;
            border-radius: 5px;
            #topmenuwrapper & { // Overrides for scrollmenu
                border: 1px solid $scrollhamburgercolor;
                fill: $scrollhamburgercolor;
                flex: 0 1 auto;
                width: 15px;
                height: 15px;
            }
        }
    }
}


// headsidemenu is the menu which comes in from the side
// when the hamburger menu is activated on the (non-scrolled )page top menu
#headsidemenu {
    z-index: 2000;
    background-color: $headsidemenucolor;
    opacity: 0.9;
    position: fixed;
    top: 0;
    right: -2000px;
    bottom: 0;
    overflow-y: auto;

    height: 100vh;

    // width: 75%;

    // padding-top: 100px;
    padding-bottom: 5px;

}

// topsidemenu is the menu which comes in from the side
// when the hamburger menu is activated on the scroll menu
#topsidemenu {
    background-color: $dropmenucolor;
    opacity: 0.9;
    position: fixed;
    top: 0;
    right: -2000px;
    bottom: 0;
    overflow-y: auto;

    height: 100vh;
    padding-bottom: 5px;


}

#topsubmenu {
    height: 0;
    overflow: hidden;
    .tophovermenu {
        background-color: $tophovermenucolor;
        overflow: hidden;
        position: relative;
        width: 100%;
        bottom: 100px; // Needs to be enough to hide menu.  Will be fine tuned in js
        z-index: 901;
    }
}

#headsubmenu {
    height: 0;
    overflow: hidden;
    .tophovermenu {
        // background-color: azure;
        overflow: hidden;
        position: relative;
        width: 100%;
        bottom: 80px;
        // z-index: 900;

    }
}

// Child of topmenubar
#sub-head-menu {
    position: relative;
    top: -5px;
    width: 100%;
    // background-color: aliceblue;
    padding-bottom: 2px;
    @include fontMenu(300);
    font-size: 1.2rem;
    @include ipad2 {
        @include menuWeight(400);
    }
    ul {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        a {
            text-decoration: none;
            &, &:visited {
                color: $subheadmenucontentcolor;
            }
            &.active-link {
                color: $highlight
              }
            @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
              &:hover {
                color: $hover
              }
            }
            li {
                line-height: 1em;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 0.1em;
                list-style-type: none;
                border-left: 1px solid $subheadmenucontentcolor;
                // vertical-align: middle;
            }
            &:first-child li, &:nth-child(2) li {
                border-left: none;
            }
            &:first-child li {
                display: none;
                @media screen and (min-width: 600px) {
                    display: list-item;
                }
                padding-right: 0;
                color: $subheadmenucontentcolor;
                &::after {
                    color: $subheadmenucontentcolor;
                    content: '>';
                    padding-left: 10px;
                // vertical-align: middle;
                }
            }
        }
        >li {
            padding-left: 10px;
            line-height: 1em;
            // vertical-align: middle;
            &:first-child {
                display: none;
                @media screen and (min-width: 600px) {
                    display: list-item;
                }
                padding-right: 0;
                color: $subheadmenucontentcolor;
                &::after {
                    color: $subheadmenucontentcolor;
                    content: '>';
                    padding-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }
}

