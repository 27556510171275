@import "site/sass-mixins.scss";

.page-heading {
    h2 {
        font-family: $font-heading;
        font-size: 2rem;
        @media screen and (min-width: 500px) {
            font-size: 2rem; 
        }
        text-align: center;
    }
    p {
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-style: italic;
        font-size: 1.4em;
        text-align: center;       
    }
}