@import "site/sass-mixins.scss";

.home-mission {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    h2 {
        padding-top: 20px;
        padding-bottom: 20px;
        flex: 0 0 80%;
        text-align: center;
        font-size: 1.9em;
        font-weight: 300;
        margin: 0;
        @include ipad2 {
            font-size: 1.9em;
            font-weight: 400;
        }
    }
    &.top-home-mission {
        min-height: 130px;
    }
    &.lighthouse-mission {
        h2 {
            font-size: 1.9rem;
        }
    }
}

.three-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 500px) {
        flex-direction: column;
    }

    flex-wrap: nowrap;
    justify-content: space-around;
    p {
        margin: 0;
        flex: 0 0 25%;
        @media (max-width: 500px) {
            width: 75%;
        }
        color:#333;
        text-align: center;
        padding-bottom: 10px;
        font-size: 1.2em;
        font-weight: 300;
        @include ipad2 {
            font-size: 1.2em;
            font-weight: 400;
        }
        a.noblue {
            color:inherit;
            text-decoration: none;
            &:visited {
                color: inherit;
            }
            &:hover {
                color:$highlight;
            }
        }
    }
}

.page-container {  // Duplicate of page-standard for headers
    max-width: 1200px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    &.full-width {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}