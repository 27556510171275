.portfolio-list  {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    // .grid-fill {
    //     display: none;
    //     @media (min-width: 499px) {
    //         display: none;
    //     }
    //     @media (min-width: 767px) {
    //         display: block;
    //         width: 50%;
    //     }
    // }

    .portfolio-square {
        box-sizing: border-box;
        &, &:visited, &:hover {
            color: inherit;
            text-decoration: none;
        }
        flex-grow: 0;
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
        @media (min-width: 499px) {
            width: 33%;
        }
        @media (min-width: 767px) {
            width: 25%;
        }
        margin-bottom: 20px;
        h2 {
            font-weight: 300;
            font-size: 1.3em;
            margin-top: 0.3em;
            margin-bottom: 0;
        }
        p {
            font-weight: 300;
            font-size: 1.1em;
            margin-top: 0.31em;
        }
        .image-padding {
            padding-bottom: 70%;
            position: relative;
            overflow: hidden;
            picture {
                position: absolute;
                left: 0;
                top: 0;
                img {
                    width: 100%;
                    transition: transform 1s ease-in-out;
                }
            }
        }
    
        &:hover img {
            transform: scale(1.07);
    
        }
    }
}

