@import "site/sass-mixins.scss";

#cookie-warning {
    -webkit-transition: opacity 1s; /* Safari */
    transition: opacity 1s;
    opacity: 0;
    z-index: 100;
    color: white;
    width: 100%;
    background-color: #333;
    padding-top: 3px;
    padding-bottom: 3px;
    position: fixed;
    bottom: 0;
    left: 0;
    .cookie-wrapper {
      @include fontFooter(300);
  
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      a {
        text-decoration: none;
      }
  
      p {
        font-size: 0.8rem;
        color: white;
        margin: 0;
        span {
          display: none;
        }
  
        @media screen and (max-width: 400px) {
          &:first-child {
            width: 100%;
            text-align: center;
          }
        }
        @media screen and (min-width: 650px) {
          &:first-child {
            span {
              display: inline;
            }
          }
        }
  
      }
  
      .cookie-learn {
        margin-left: 5px;
        p {
          color: $highlight;
        }
  
      }
      .cookie-confirm {
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
        border-left: 1px solid white;
        border-right: 1px solid white;
        color: $highlight;
      }
    }
  }
  
  