// @import "src/sass-mixins.scss";

/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
    src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }


  /* open-sans-400 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://d1c0razx1upi8m.cloudfront.net/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

//   /* dosis-500 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://d1c0razx1upi8m.cloudfront.net/fonts/dosis-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Dosis Medium'), local('Dosis-Medium'),
       url('https://d1c0razx1upi8m.cloudfront.net/fonts/dosis-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://d1c0razx1upi8m.cloudfront.net/fonts/dosis-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    //    url('/fonts/dosis-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://d1c0razx1upi8m.cloudfront.net/fonts/dosis-v7-latin-500.woff') format('woff'), /* Modern Browsers */
       url('https://d1c0razx1upi8m.cloudfront.net/fonts/dosis-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://d1c0razx1upi8m.cloudfront.net/fonts/dosis-v7-latin-500.svg#Dosis') format('svg'); /* Legacy iOS */
}
