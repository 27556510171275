#page-web-design {
    font-size: 1rem;
}

.flex-row {
    display: flex;
    flex-direction: column;
    // align-items: center;
    flex-wrap: nowrap;
    @media (min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .flex-row-content, .flex-row-image {
        box-sizing: border-box;
        flex: 0 0 50%;
        min-height: 200px;
    }
    .flex-row-content {
        background-color: cyan;
        padding: 10px 20px 10px 20px;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
            /* IE10+ specific styles go here */  
            padding: 0;
         }

        font-weight: 400;
        font-size: 0.75rem;
        h2 {
            font-family: 'Dosis', sans-serif;
            text-align: center;
            font-weight: 300;
            font-size: 2.4em;
        }
        h4 { 
            font-family: 'Dosis', sans-serif;
            text-align: center;
            font-weight: 300;
            font-size: 2.0em;
        }
        p, li {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 1.6em;
        }
        p.fact {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-style: italic;
            font-size: 1.7em;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .flex-row-image {

        @media (max-width: 599px) {
            width: 100%;
        }

        align-self: center;
        // background-color: aqua;

        box-sizing: border-box;
        padding: 20px;
        @media (min-width: 600px) {
            padding: 50px;
        }
        @media (min-width: 768px) {
            padding: 100px;
        }

        &.small-border {
            padding: 20px;
        }
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
            /* IE10+ specific styles go here */  
            padding: 0;
         }
        h2 {
            color: #333;
            font-family: 'Dosis', sans-serif;
            text-align: center;
            font-weight: 300;
            font-size: 1.8em;
            text-align: center;

        }
    }

    @media (min-width: 600px) {
        &.flex-row-reverse {
            .flex-row-image {
                order: 1;
            }
            .flex-row-content {
                order: 2;
            }
        }
    }
}