@import "site/sass-mixins.scss";


// #bannerwrapper {
//     position: relative;
//     min-height: 66.6vw;
//     @media screen and (min-width: 550px) {
//         min-height: 33.3vw;
//     }
//     @media screen and (min-width: 1024px) {
//         min-height: 25vw;
//     }
//     picture img { // Needed if srcset fails and default image chosen
//         width: 100%;
//     }
// }

.image-load-wrapper {
    width: 100%;
    z-index: 2;
    position: relative;
    background-color: white;
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.standard-banner-div {
    padding-bottom: 66%;
    @media screen and (min-width: 550px) {
        padding-bottom: 33%;
    }
    @media screen and (min-width: 1024px) {
        padding-bottom: 25%;
    }
}

#home-banner-div {
    padding-bottom: 75%;
    @media screen and (min-width: 551px) {
        padding-bottom: 45%;
    }
    @media screen and (min-width: 1113px) {
        padding-bottom: 32%;
    }
}

// h2.hero-image-text {
//     @include fontHeading(500);
//     color: white;
//     &.hero-image-text-dark {
//         color:#333;
//     }
//     &.hero-image-text-gradient {
//         background-image: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0) 100%); 
//     }


//     position: absolute;
//     width: 100%;
//     padding: 10px 10px 30px 21px;
//     box-sizing: border-box;
//     left: 0px;
//     top: 0px;
//     margin: 0;
//     // TODO: Position text with same left offset as page
//     // For small screen position text in center, with subititle under image
//     font-size: 1.5rem;
//     @media screen and (min-width: 500px) {
//         font-size: 2rem; 
//     }
// }
.portfolio-summary {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 10px 21px;
    left: 0px;
    top: 0px;
    color:#333;
    .portfolio-summary-heading {
        margin: 0;
        margin-bottom: 5px;
        @include fontHeading(500);
        font-size: 1.7rem;
        @media screen and (min-width: 500px) {
            font-size: 2rem; 
        }

    }
    .portfolio-summary-text {
        margin-top: 0;
        margin-bottom: 5px;
        width: 30%;
        @include fontCopy(300);
        font-size: 0.9rem;
        @media screen and (min-width: 500px) {
            font-size:1.3rem; 
        }
        display: none;
        @media screen and (min-width: 550px) {
            display: inherit;
        }

    }
}

#submenuinsert {
    .submenu-portfolio {
        display: none;
        @media screen and (min-width: 700px) {
            display: inherit;
        }
        li {
            font-size: 0.8em;
            @media screen and (min-width: 850px) {
                font-size: 1em;
            }
        }
    }

}


