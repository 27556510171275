@import "site/sass-mixins.scss";

#menucontent {
    @media screen and (max-width: 350px) {
        display: none;
    }
    overflow: hidden; // To prevent scroll bar caused by reducing the line height
    // margin: 15px 0 15px 0;
    margin: 0;
    // line-height: 2em;

    @include fontMenu(300);
    // opacity: 0;
    // transition: opacity 0.5s ease;
    // .wf-opensans-n3-active &, .wf-opensans-n3-inactive & {
    //     opacity: 1;
    // }
    // font-size: 1.5rem;
    font-size: 1.3rem;
    // NICE: Post-load 400 size font if on ipad
    @include ipad2 {
        @include menuWeight(400);
    }
    p {
        text-align: end;
        color: $highlight;
        margin: 0;
        padding-right: 5px;
        // line-height: 1.2em;
    }

    ul {
        display: flex;
        flex-direction: row;
        // justify-content: flex-end;
        justify-content: flex-end;
        margin: 0;
        // padding-left: 0;
        padding-left: 10px;

        // background-color: beige;
        li {

            flex: 0 0 auto;

            line-height: 1.2em; // Sets the size of the bars (which are left borders)


            padding-left: 10px;
            padding-right: 10px;
            border-left: 1px solid $menucontentcolor;
            #topmenuwrapper & {
                border-left: none;
            }

            &:first-child {
                border-left: none;
                padding-left: 0;
            }
            display: inline-block;
            a {
                position: relative; // Shift the text up slightly to centre it among the side bars
                top: -0.05em;
                text-decoration: none;
                &, &:visited {
                    color: $menucontentcolor;
                    #topmenuwrapper & {
                        color:  $scrollmenucontentcolor;
                    }

                }
              &.active-link {
                color: $highlight;
              }
              @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
                &:hover {
                    color: $hover;
                  }
              }



            }
        }
    }
}

.hovermenucontent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @include fontMenu(300);
    font-size: 1rem;
    @include ipad2 {
        @include menuWeight(400);
    }

    padding-top: 5px;
    padding-bottom: 5px;
    .hovermenucolumn {
        flex: 0 0 auto;
        ul {
            padding-left: 0;
            // background-color: cyan;
            margin: 0;
            li {
                padding-left: 10px;
                padding-bottom: 0.2em;
                &:first-child, &.drop-menu-spacer {
                    padding-left: 0;
                    @include menuWeight(600)
                }
                &.drop-menu-spacer {
                    padding-top: 0.5em;
                }
                list-style-type: none;
                a {
                    text-decoration: none;
                    &, &:visited {
                        color: $hovermenucontentcolor;
                        #topmenuwrapper & {
                            color: $drophovermenucontentcolor;
                        }
                    }
                    @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
                    &:hover {
                        color: $hover;

                    }
                    }
                    &.active-link {
                        color: $highlight;
                        #topmenuwrapper & {
                            color:$highlight;
                        }

                    }
                }
            }
        }
    }

}


// #headsidemenu {
.sidemenucontent {

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include fontMenu(300);
    font-size: 1rem;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    .sidemenucolumn {
        color: $sidemenucontentcolor;
        #headsidemenu & {
            color: $headsidemenucontentcolor;
        }
        ul {
            padding: 0;
            margin-top: 0;
            li {
                list-style-type: none;
                a, a:visited {
                    text-decoration: none;
                    color: $sidemenucontentcolor;
                    #headsidemenu & {
                        color: $headsidemenucontentcolor;
                    }
                }
                @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
                    a:hover {
                        text-decoration: none;
                        color: $sidemenucontentcolor;
                        #headsidemenu & {
                            color: $headsidemenucontentcolor;
                        }
                    }
                }
                &:first-child {
                font-size: 1.3em;
                }
                a.active-link {
                    color: $highlight;
                    #headsidemenu & {
                        color: $highlight;
                    }
                }
            }
        }

        // background-color: azure
    }
    // @include ipad2 {
    //     // font-size: 1.0em;
    //     font-weight: 400;
    // }
}

// #sidemenuwrapper {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     font-family: 'Open Sans', sans-serif;
//     font-weight: 300;
//     font-size: 1rem;
//     @include ipad2 {
//         // font-size: 1.0em;
//         font-weight: 400;
//     }

//     padding-top: 5px;
//     padding-bottom: 5px;
//     .hovermenucolumn {
//         flex: 0 0 auto;
//         ul {
//             padding-left: 0;
//             // background-color: cyan;
//             margin: 0;
//             li {
//                 padding-left: 10px;
//                 padding-bottom: 0.2em;
//                 &:first-child {
//                     padding-left: 0;
//                     font-weight: 600;
//                 }
//                 list-style-type: none;
//                 a {
//                     text-decoration: none;
//                     &, &:visited {
//                         color: #333;
//                     }
//                   &:hover, &.active-link {
//                     color: #f28b32;

//                   }
//                 }
//             }
//         }
//     }

// }

