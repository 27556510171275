@import "site/sass-mixins.scss";

#page-notfound {

    .hovermenucontent .hovermenucolumn ul li a, #hovermenucontent .hovermenucolumn ul li a {
        &, &:visited {
            color:#333;
        }
        &:hover {
            color: $highlight;
        }
    }

}