@import "site/sass-mixins.scss";

#bannerwrapper.photobanner {
    position: relative;
    min-height: 66.6vw;
    @media screen and (min-width: 550px) {
        min-height: 33.3vw;
    }
    @media screen and (min-width: 1200px) {
        min-height: 25vw;
    }
    picture {
        line-height: 0;
        position: absolute;
        width: 100%
    }
    picture img { // Needed if srcset fails and default image chosen
        width: 100%;
    }
}

h2.hero-image-text {
    @include fontHeading(500);
    color: white;
    &.hero-image-text-dark {
        color:#333;
    }
    &.hero-image-text-gradient {
        background-image: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0) 100%); 
    }


    position: absolute;
    width: 100%;
    padding: 10px 10px 30px 21px;
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    margin: 0;
    // TODO: Position text with same left offset as page
    // For small screen position text in center, with subititle under image
    font-size: 1.5rem;
    @media screen and (min-width: 500px) {
        font-size: 2rem; 
    }
    display: none;
}