.carousel-wrapper  {
    width: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}
.carousel-next, .carousel-prev  {
    flex: 0 0 40px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    .arrow-icon {
        flex: 0 0 30px;
        fill: #aaa;
    }
    &:hover  {
        background-color: #eee;
    }
}

.carousel-next {
    margin-left: 5px;
}

.carousel-prev {
    margin-right: 5px;
    svg {
        transform: scaleX(-1);
    }
}

@keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes shiftright2 {
    from {left: 0;}
    to {left: 52%;}
}

@keyframes shiftleft2 {
    from {left: 0;}
    to {left: -52%;}
}

@keyframes shiftright3 {
    from {left: 0;}
    to {left: 34%;}
}

@keyframes shiftleft3 {
    from {left: 0;}
    to {left: -34%;}
}

@keyframes shiftright4 {
    from {left: 0;}
    to {left: 25.333%;}
}

@keyframes shiftleft4 {
    from {left: 0;}
    to {left: -25.333%;}
}

@keyframes shiftright5 {
    from {left: 0;}
    to {left: 20.25%;}
}

@keyframes shiftleft5 {
    from {left: 0;}
    to {left: -20.25%;}
}

.carousel-content  {
    flex: 0 1 100%;
    line-height: 0;

    display: flex;
    flex-direction: row;
    // flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .carousel-node {
        position: relative;
        left: 0;
        // transition: left 1s;
        // animation-name: shiftright;
        animation-duration: 1s;
        flex: 0 1 48%;
        @media (min-width: 550px) {
            flex: 0 1 32%;
        }
        @media (min-width: 768px) {
            flex: 0 1 24%;
        }
        @media (min-width: 1024px) {
            flex: 0 1 19%;
        }
        display: block;

        p {
            text-align: center;
            background-color: chartreuse;
        }
    }

    .portfolio-square-0, .portfolio-square-1 {
        display: block;
    }
    .portfolio-square-2, .portfolio-square-3, .portfolio-square-4, .portfolio-square-5, .portfolio-square-6 {
        display: none;
    }
    @media (min-width: 499px) {
        .portfolio-square-2 {
            display: block;
        }
    }
    @media (min-width: 767px) {
        .portfolio-square-3 {
            display: block;
        }
    }
    @media (min-width: 1024px) {
        .portfolio-square-4 {
            display: block;
        }
    }

}

