@import "site/sass-mixins.scss";
$dropmenucolor: red;


#loginpage {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

}

#account-panel {
    flex: 1 1 50%;
}

#actions-panel {
    flex: 1 1 50%;
}

#account-divider {
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
    }
    flex: 0 0 3px;
    margin: 60px 20px 0px 20px;
    background-color: $appzuka;
}

input[type="button"] {
    flex: 0 0 auto;
    // background-color: $dropmenucolor;
    font-size: 1.1em;
    border: none;
    color: white;
    padding: 5px 20px;
    margin: 10px 0 0 0;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $appzuka;
    margin: 10px 0 0 0;
}

input[type="button"].ghost {
    color: $appzuka;
    background-color: white;
    border: 2px solid $appzuka;
}



.login-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    >h2 {
        flex: 0 0 100%;
        margin-bottom: 0;
    }
    form {
        flex: 0 0 100%;
        padding-right: 0;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            flex: 0 1 50%;
            box-sizing: border-box;
        }
        display: flex;
        flex-direction: column;
        // background-color: aliceblue;
        .input-element {
            position: relative;
            margin-top: 10px;
            padding-top: 20px;
            // padding-left: 5px;
            // padding-right: 5px;

            p {
                user-select: none;  
                margin: 0;
                color: #888;
                position: absolute;
                transition: top 1s, font-size 1s;
                // transition: font-size 2s;
                top: 23px;
                padding-left: 5px;
                &.input-non-empty {
                    font-size: 0.7em;
                    top: 0;
                }
                span {
                    padding-left: 5px;
                    opacity: 0.5;
                    font-size: 0.8em;
                    display: none;
                    color: red;
                }
                &.input-invalid span {
                    display: inline;
                }
                &.input-invalid.input-non-empty span {
                    font-size: 1em;
                }
            }
            input[type="text"], input[type="email"], input[type="password"] {
                position: relative;
                z-index: 1000;
                width: 100%;
                box-sizing: border-box;
                border: none;
                border-bottom: 3px solid gray;
                font-size: 1.1em;
                background-color: rgba(0,0,0,0.05);
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                padding: 5px 5px 3px 5px; // iOS override
                // &:focus {
                //     outline-color: rgb(77, 144, 254); // #4D90FE
                //     outline-offset: -2px;
                //     outline-style: auto;
                //     outline-width: 5px;
                // }
            }
        }
        p.privacy-note {
            color: #888;
            font-size: 0.7em;
            margin: 2px 0 2px 0;
        }
        p.test-warning {
            color: red;
            // padding-left: 5px;
        }
        .submit-area {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 500px) {
                flex-direction: row;
                align-items: flex-end;
            }
            justify-content: space-between;

            input[type="submit"], input[type="button"] {
                flex: 0 0 auto;
                // background-color: $dropmenucolor;
                font-size: 1.1em;
                border: none;
                color: white;
                padding: 5px 20px;
                margin: 10px 0 0 0;
                border-radius: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
            input[type="button"] {
                background-color: $appzuka;
                margin: 10px 0 0 0;
            }
            input[disabled] {
                background-color: rgba(0,0,0,0.05);
                color: #888;
            }
            @media screen and (min-width: 500px) {
                p.privacy-note {
                    padding-left: 10px;;
                }
            }
            @media screen and (max-width: 499px) {
                p.privacy-note {
                    margin: 7px 5px 2px 5px;
                }
            }

        }


    }
}