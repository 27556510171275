@import "site/sass-mixins.scss";

.portfolio-top-header {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .portfolio-title {
        flex: 1 1 100%;
        h2 {
            display: inline;
            margin: 0;

        }
        p {
            font-size: 1.1rem;
        }
    }

    .pagespeed-results-wrapper {
        flex: 0 5 50%;
        .lighthouse-gauge-wrapper {
            padding-left: 20px;
        }
    }
}

.portfolio-banner-title {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 10px 21px;
    left: 0px;
    top: 0px;
    color:#333;
    h2 {
        margin: 0;
        margin-bottom: 5px;
        @include fontHeading(500);
        font-size: 1.7rem;
        @media screen and (min-width: 500px) {
            font-size: 2rem; 
        }

    }
    p {
        margin-top: 0;
        margin-bottom: 5px;
        width: 30%;
        @include fontCopy(300);
        font-size: 0.9rem;
        @media screen and (min-width: 500px) {
            font-size:1.3rem; 
        }
        display: none;
        @media screen and (min-width: 550px) {
            display: inherit;
        }

    }
}

.portfolio-content {
    .portfolio-header {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 650px) {
            flex-direction: row;
        }
        flex-wrap: nowrap;
        padding-bottom: 20px;
        .portfolio-picture-wrapper, .portfolio-picture-wrapper-reverse {
            order: 1;
            flex: 0 0 50%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            picture {
                flex: 0 1 90%;
                // width: 100%;
                img {
                    width: 100%;
                }
            }
            img {
                width: 80%;
            }
        }
        .portfolio-picture-wrapper-reverse {
            order: 3;
        }

        .portfolio-header-desc {
            order: 2;
            flex: 0 0 50%;
            box-sizing: border-box;
            h2 {
                margin-top: 0;
            }
        }
        .portfolio-tech-icons, .portfolio-tech-icons-reverse {
            order: 1;
            @media screen and (min-width: 650px) {
                order: 3;
            }

            flex: 0 0 50%;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            .tech-icon {
                padding-left: 10px;
                padding-right: 10px;
                box-sizing: border-box;
                width: 50px;
                height: 50px;
                @media screen and (min-width: 600px) {
                    width: 75px;
                    height: 75px;
                }
                @media screen and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                }
            }
        }
        .portfolio-tech-icons-reverse {
            order: 1;
        }

    }
}
