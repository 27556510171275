

.lighthouse-results-wrapper, .pagespeed-results-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    .lighthouse-gauge-wrapper {
        flex: 0 0 23%;
        text-decoration: none;
        position: relative;
        contain: content;
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .lighthouse-gauge {
            // padding-left: 4vw;
            // padding-right: 4vw;

            // box-sizing: border-box;
            // flex: 0 0 100%;
            width: 20vw;
            @media (min-width: 650px) {
                width: 10vw;
            }
            max-width: 90%;
            position: relative;

            font-size: 0;
            .lh-gauge-svg {
                // width: 100%;
            //                 width: 10vw;

                .lh-gauge-base {
                    fill: none;
                    stroke: lightgray;
                    stroke-width: 8px;
                }
                .lh-gauge-arc {
                    fill: none;
                    stroke: green;
                    stroke-width: 8px;
                    animation: load-gauge 1s ease forwards;
                    animation-delay: 250ms;
                    stroke-linecap: round;
                }
            }

            .lighthouse-gauge-percent {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 5vw;
                @media (min-width: 650px) {
                    font-size: 3vw;
                }
                color: green;

            }
        }

        .lighthouse-gauge-label {
            width: 100%;
            padding-top: 0.5em;
            font-size: 3vw;
            @media (min-width: 550px) {
                font-size: 1.1em;
            }
            text-align: center;
        }
    }
}

.pagespeed-results-wrapper {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // align-items: flex-start;
    // width: 100%;
    .lighthouse-gauge-wrapper {
        // flex: 0 0 23%;
        // text-decoration: none;
        // position: relative;
        // contain: content;
        // // height: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // align-items: center;
        .lighthouse-gauge {
            // padding-left: 4vw;
            // padding-right: 4vw;

            // box-sizing: border-box;
            // flex: 0 0 100%;
            width: 60px;
            // @media (min-width: 650px) {
            //     width: 10vw;
            // }
            // max-width: 90%;
            // position: relative;

            // font-size: 0;
            .lh-gauge-svg {
                // width: 100%;
            //                 width: 10vw;

                .lh-gauge-base {
                    // fill: none;
                    // stroke: lightgray;
                    stroke-width: 12px;
                }
                .lh-gauge-arc {
                    // fill: none;
                    // stroke: green;
                    stroke-width: 12px;
                    // animation: load-gauge 1s ease forwards;
                    // animation-delay: 250ms;
                    // stroke-linecap: round;
                }
            }

            .lighthouse-gauge-percent {
                font-weight: 600;
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                font-size: 1.5rem;
                // @media (min-width: 650px) {
                //     font-size: 3vw;
                // }
                // color: green;

            }
        }

        .lighthouse-gauge-label {
            // If the label is shorter than 60px the guage can be compressed
            min-width: 70px;
        //     width: 100%;
        //     padding-top: 0.5em;
        //     font-size: 3vw;
        //     @media (min-width: 550px) {
        //         font-size: 1.1em;
        //     }
        //     text-align: center;
        }
    }
}


.lighthouse-results-metrics {
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9em;
    .lighthouse-results-metric-wrapper {
        padding-top: 5px;
        padding-bottom: 5px;
        flex: 0 0 100%;
        @media (min-width: 550px) {
            flex: 0 0 48%;
        }
        @media (min-width: 650px) {
            flex: 0 0 100%;
        }
        @media (min-width: 1024px) {
            flex: 0 0 48%;
        }
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        .lighthouse-results-metric-label {
            flex-basis: auto;
            margin: 0;
            padding-right: 5px;
        }
        .lighthouse-results-metric-filler {
            flex: 1 1 auto;

        }
        .lighthouse-results-metric-value {
            flex: 0 0 auto;
            margin: 0;

        }
        .tick-icon {
            padding-left: 10px;
            padding-top: 3px;
            flex: 0 0 1em;
            height: 1em;
        }


    }
}




.lighthouse-icon, .webpagetest-icon {
    width: 60px;
    @media (min-width: 550px) {
        width: 100px;
    }
    float: right;
    padding-left: 10px;
}

// @keyframes dash {
//     0% {
//         opacity: 1;
//         stroke-dasharray: 0 345;
//     }
//     100% {
//         opacity: 1;
//         stroke-dasharray: 200 345;
//     }
//     // 95% {
//     //         stroke-dashoffset: 5;
//     //         opacity: 1;
//     // }
//     // 100% {
//     //         stroke-dashoffset: 5;
//     //         opacity: 0;
//     // }
// }


// @keyframes fade {
//     0% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }

#nick-gauge-0 {
    // Unclear if this improves performance
    will-change: opacity;
//     // stroke-dashoffset: 0;
//     stroke-dasharray: 0 345;
//     opacity: 1;
//     animation: dash 2s ease-out forwards;
//     animation: fade 1s ease-out forwards;
//     animation-name: dash, fade;
//     animation-duration: 2000ms, 500ms;
//     animation-timing-function: ease-out, linear;
//     animation-direction: forwards, forwards;
//     animation-iteration-count: 1, 1;
//     animation-play-state: paused, paused;
}
