.carousel-node {
    &:hover img {
        opacity: 0.7;

    }
    picture img {
        box-sizing: border-box;
        border: 2px solid #aaa;
        border-radius: 5px;
        width: 100%;
    }

    &, &:visited, &:hover {
        color: inherit;
        text-decoration: none;
    }
    flex: 0 1 48%;
    // flex-grow: 0;
    // width: 50%;
    // padding-left: 5px;
    // padding-right: 5px;
    @media (min-width: 499px) {
        flex: 0 1 32%;
    }
    @media (min-width: 767px) {
        flex: 0 0 24%;
    }
    @media (min-width: 1024px) {
        flex: 0 0 19%;
    }
    // h2 {
    //     font-weight: 300;
    //     font-size: 1.3em;
    //     margin-top: 0.3em;
    //     margin-bottom: 0;
    // }
    // p {
    //     font-weight: 300;
    //     font-size: 1.1em;
    //     margin-top: 0.31em;
    // }
    // .image-padding {
    //     padding-bottom: 70%;
    //     position: relative;
    //     overflow: hidden;
    //     picture {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         img {
    //             width: 100%;
    //             transition: transform 1s ease-in-out;
    //         }
    //     }
    // }

    &:hover img {
        // transform: scale(1.07);

    }
}