@import "site/sass-mixins.scss";

.page-standard {
    max-width: 1200px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    @include fontCopy(400);
    &.full-width {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    color: #333;
    padding-bottom: 50px;
    &.no-bottom-padding {
        padding-bottom: 0;
    }

    background-color: white;
    h2 {
        @include fontHeading(500);
        // Avoid break between heading and first paragraph does not work.
        // break-after: avoid-column;
    }
    p {
        font-size: 1.2em;
    }

    p, li {
        break-inside: avoid-column;
        // line-height: normal;
        // &:first-child {
        //     break-before: avoid-column;
        // }
    }
    li {
        padding-bottom: 0.3em;
    }

    .two-columns {
        @media screen and (min-width: 768px) {
            column-count: 2;  
        }
        p, li {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    &.page-heading {
        h2 {
            @include fontHeading(500);
            font-size: 2rem;
            @media screen and (min-width: 500px) {
                font-size: 2rem; 
            }
            text-align: center;
        }
        p {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-style: italic;
            font-size: 1.4em;
            text-align: center;       
        }
    }

}
