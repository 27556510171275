$dropmenucolor: red;

.form-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    >h2 {
        flex: 0 0 100%;
        margin-bottom: 0;
    }
    form {
        flex: 0 0 100%;
        padding-right: 0;
        @media screen and (min-width: 1024px) {
            flex: 0 1 50%;
            box-sizing: border-box;
            padding-right: 10px;
        }
        display: flex;
        flex-direction: column;
        // background-color: aliceblue;
        .input-element {
            position: relative;
            margin-top: 10px;
            padding-top: 20px;
            // padding-left: 5px;
            // padding-right: 5px;

            p {
                user-select: none;  
                margin: 0;
                color: #888;
                position: absolute;
                transition: top 1s, font-size 1s;
                // transition: font-size 2s;
                top: 23px;
                padding-left: 5px;
                &.input-non-empty {
                    font-size: 0.7em;
                    top: 0;
                }
                span {
                    padding-left: 5px;
                    opacity: 0.5;
                    font-size: 0.8em;
                    display: none;
                    color: red;
                }
                &.input-invalid span {
                    display: inline;
                }
                &.input-invalid.input-non-empty span {
                    font-size: 1em;
                }
            }
            input[type="text"], input[type="email"], textarea {
                position: relative;
                z-index: 1000;
                width: 100%;
                box-sizing: border-box;
                border: none;
                border-bottom: 3px solid gray;
                font-size: 1.1em;
                background-color: rgba(0,0,0,0.05);
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                padding: 5px 5px 3px 5px; // iOS override
                // &:focus {
                //     outline-color: rgb(77, 144, 254); // #4D90FE
                //     outline-offset: -2px;
                //     outline-style: auto;
                //     outline-width: 5px;
                // }
            }
        }
        p.privacy-note {
            color: #888;
            font-size: 0.7em;
            margin: 2px 0 2px 0;
        }
        p.test-warning {
            color: red;
            // padding-left: 5px;
        }
        .submit-area {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 500px) {
                flex-direction: row;
                align-items: flex-end;
            }
            justify-content: space-between;

            input[type="submit"] {
                flex: 0 0 auto;
                // background-color: $dropmenucolor;
                font-size: 1.1em;
                border: none;
                color: white;
                padding: 5px 20px;
                margin: 10px 10px 0 0;
                border-radius: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
            input[disabled] {
                background-color: rgba(0,0,0,0.05);
                color: #888;
            }
            @media screen and (max-width: 500px) {
                p.privacy-note {
                    margin: 7px 5px 2px 5px;
                }
            }

        }


    }
    #submitted-message {
        padding-left: 0;
        margin-top: 27px;
        flex: 0 1 100%;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 1024px) {
            flex: 0 0 50%;
            box-sizing: border-box;
            padding-left: 10px;
        }
        // background-color: beige;
        >p {
            padding: 5px 10px;
        }

        p {
            color: #777;
            margin-top: 2px;
            margin-bottom: 2px;
            &.message-id {
                font-size: 0.7em;
            }
            &.test-warning {
                color: red;
            }
            &.submit-ok, &.submit-pending, &.submit-failed  {
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            &.submit-ok {
                background-color: rgb(222, 253, 222);
            }
            &.submit-pending {
                background-color: rgb(252, 233, 198);
            }
            &.submit-failed {
                background-color: rgb(252, 198, 198);
            }
        }
        .result-table {
            display: grid;
            grid-template-columns: max-content auto;
            padding-top: 10px;
            padding-left: 10px;
            p {
                margin-top: 4px;
                margin-bottom: 4px;
                &:nth-child(2n) {
                    padding-left: 20px;
                }
            }
        }
        .resubmit-button {
            align-self: flex-end;
            // background-color: $dropmenucolor;
            font-size: 1.1em;
            border: none;
            color: white;
            padding: 5px 20px;
            margin: 10px 0;
            border-radius: 5px;
        }
    }
}