#bannerwrapper .rocket-banner {// Placeholder only - can be removed
    position: relative;
    display: flex;
    font-size: 1rem;
    // background-color: #E05A63;
    background-color: #A81F29;
    min-height: 500px;
    flex-direction: column;
    justify-content: space-between;
    .banner-heading {
        background-color: #A81F29;
        font-size: 1em; 
        color: white;
        padding-top: 1em;
        padding-bottom: 1em;
        z-index: 500;

        >h2 {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 5vw;
            @media screen and (min-width: 767px) {
                font-size: 3vw;
            }
            text-align: center;
            padding-left: 50px;
            padding-right: 50px;
            margin: 0;
            line-height: 1.5em;
        }
        .banner-heading-bullets {
            height: 0;
            width: 60%;
            float: right;
            padding-top: 1em;
            padding-right: 2em;
            >h2 {
                padding-top: 0.5em;
                font-size: 1.2em;
                @media screen and (min-width: 767px) {
                    font-size: 1.4em;
                }
                line-height: 1.2em;
                color: white;
                font-family: 'Open Sans', sans-serif;
                font-weight: 300;
                margin: 0;
            }
        }

    }
    .svg-wrapper svg { // If the SVG is an inline-block the whitespace causes an extra line
        display: block;
    }
}

.lighthouse-link {
    color: inherit;
    text-decoration: none;
}

.lighthouse-gauges {
    margin-top: 10px;
    margin-bottom: 10px;
}


