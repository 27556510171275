@import "site/sass-mixins.scss";


$blockradius: 20px;
$bordercolor: #ccc;

.service-block-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .service-three-block {

        &, &:hover, &:visited {
            text-decoration: none;
            color: inherit;
        }
        flex: 0 1 100%;
        @media (min-width: 650px) {
            flex: 0 1 32%;
        }


        border-left: 1px solid $bordercolor;
        border-right: 1px solid $bordercolor;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > .service-svg {
            flex: 0 1 75%;
            max-width: 170px;
            .icon-color-1 {
                fill: #f28b32;
            }
            .icon-color-2 {
                fill: yellow;
            }
        }

        @media (min-width: 650px) {
            &.service-icon {
                order: 1;
            }
            &.service-title {
                order: 2;
            }
            &.service-desc {
                order: 3;
            }
        }

        &.service-icon {
            padding-top: 10px;
            border-top: 1px solid $bordercolor;
            border-top-left-radius: $blockradius;
            border-top-right-radius: $blockradius;
        }

        &.service-title, &.service-desc {
            text-align: center;
            h2 {
                color: #555;
                font-family: $font-heading;
                font-weight: 500;
                font-size: 2.2em;
                margin: 0;
                padding-bottom: 0.5em;
            }
            p {
                margin: 0;
                @media (max-width: 649px) {
                    max-width: 75%;
                }
                padding-left: 10px;
                padding-right: 10px;
                font-family: "Open Sans", sans-serif;
                font-weight: 300;
                font-size: 1.1em;
            }
        }

        &.service-desc {
            align-items: flex-start;
            padding-bottom: 10px;
            border-bottom: 1px solid $bordercolor;
            border-bottom-left-radius: $blockradius;
            border-bottom-right-radius: $blockradius;
            @media (max-width: 649px) {
                margin-bottom: 20px;
            }
        }

    }
}
