.article-thumbnail-container {
    // background-color: cyan;
    width: 100%;
    position: relative;
    display: block;


    @supports (display: grid) {
        // background-color: red;

        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        @media screen and (min-width: 700px) {
            & {
                grid-template-columns: 1fr 1fr;  
            }
        }
        @media screen and (min-width: 1024px) {
            & {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .article-thumbnail {
        display: inline-block;
        // position: relative;
        padding-left: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        width: 95%;
        @media screen and (min-width: 600px) {
            width: 48%;
        }
        @media screen and (min-width: 769px) {
            width: 31%;
        }

        @supports (display: grid) {
            width: 100%;
            max-width: 350px;
            padding: 0;
            justify-self: center;
        }
        position: relative;

        .article-thumbnail-wrapper {
            width: 100%;
            picture {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }

        .article-thumbnail-wrapper {

            padding-bottom: 75%;
            position: relative;
            overflow: hidden;
            h2, p {
                position: absolute;
                margin: 0;
                color: white;
            }
            h2 {
                left: 5px;
                top: 5px;
            }
            p {
                max-height: 60%;
                bottom: 5px;
                left: 5px;
                overflow: hidden;
            }
            .article-thumbnail-image {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                img {
                    width: 100%;
                }
                
            }
            .article-thumbnail-image-filter {
                position:  absolute;
                top: 0;
                left: 0;
                width: 100%;
                // height: 200px;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
            }
        }

        .ribbon {
            position: absolute;
            right: -5px; top: -5px;
            z-index: 1;
            overflow: hidden;
            width: 75px; height: 75px;
            text-align: right;
          }
          .ribbon span {
            font-size: 10px;
            font-weight: bold;
            color: #FFF;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 100px;
            display: block;
            background: #79A70A;
            background: linear-gradient(#F79E05 0%, #8F5408 100%);
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px; right: -21px;
          }
          .ribbon span::before {
            content: "";
            position: absolute; left: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid #8F5408;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #8F5408;
          }
          .ribbon span::after {
            content: "";
            position: absolute; right: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid #8F5408;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #8F5408;
          }



    }



    //     // margin-top: 20px;
    //     // background-color: cyan;
    //     // box-sizing: border-box;
    //     // flex: 0 1 calc(25% - (60px / 3));
    //     // padding-bottom: 50%;
    //     // position: relative;






}

.article-content {

    .sidenote {
        width: 100%;
        box-sizing: border-box;
        background-color: #eee;
        padding: 10px;
        color: #333;
        font-weight: 300;
        break-before: column;
        margin-top: 0;
    }

    .fakelink {
        color: blue;
    }

    .multi-columns {
      text-align: justify;
      @media screen and (min-width: 1025px) {
          column-count: 2; 
      }
      p, li {
          &:first-child {
              margin-top: 0;
          }
      }
  }

}

/*
 * Visual Studio 2015 dark style
 * Author: Nicolas LLOBERA <nllobera@gmail.com>
 */

 .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #1E1E1E;
    color: #DCDCDC;
  }
  
  .hljs-keyword,
  .hljs-literal,
  .hljs-symbol,
  .hljs-name {
    color: #569CD6;
  }
  .hljs-link {
    color: #569CD6;
    text-decoration: underline;
  }
  
  .hljs-built_in,
  .hljs-type {
    color: #4EC9B0;
  }
  
  .hljs-number,
  .hljs-class {
    color: #B8D7A3;
  }
  
  .hljs-string,
  .hljs-meta-string {
    color: #D69D85;
  }
  
  .hljs-regexp,
  .hljs-template-tag {
    color: #9A5334;
  }
  
  .hljs-subst,
  .hljs-function,
  .hljs-title,
  .hljs-params,
  .hljs-formula {
    color: #DCDCDC;
  }
  
  .hljs-comment,
  .hljs-quote {
    color: #57A64A;
    font-style: italic;
  }
  
  .hljs-doctag {
    color: #608B4E;
  }
  
  .hljs-meta,
  .hljs-meta-keyword,
  .hljs-tag {
    color: #9B9B9B;
  }
  
  .hljs-variable,
  .hljs-template-variable {
    color: #BD63C5;
  }
  
  .hljs-attr,
  .hljs-attribute,
  .hljs-builtin-name {
    color: #9CDCFE;
  }
  
  .hljs-section {
    color: gold;
  }
  
  .hljs-emphasis {
    font-style: italic;
  }
  
  .hljs-strong {
    font-weight: bold;
  }
  
  /*.hljs-code {
    font-family:'Monospace';
  }*/
  
  .hljs-bullet,
  .hljs-selector-tag,
  .hljs-selector-id,
  .hljs-selector-class,
  .hljs-selector-attr,
  .hljs-selector-pseudo {
    color: #D7BA7D;
  }
  
  .hljs-addition {
    background-color: #144212;
    display: inline-block;
    width: 100%;
  }
  
  .hljs-deletion {
    background-color: #600;
    display: inline-block;
    width: 100%;
  }


  /* Overrides */

  .hljs {
    display: inline-block;
  }

  pre {
    text-align: center;
  }