#page-mobile-apps {
    .app-store-icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .app-store-icon-badge {
            display: flex;
            flex-direction: column;
            align-items: center;
            .tech-icon {
                width: 75px;
            }
        }
    }
}