@import "site/sass-mixins.scss";

// Overrides


@mixin dark-header-colors() {
        // background-color: #3c789c; //68b0e0
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3c789c+0,68b0e0+100 */
    background: #3c789c; /* Old browsers */
    background: -moz-linear-gradient(left, #3c789c 0%, #68b0e0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #3c789c 0%,#68b0e0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #3c789c 0%,#68b0e0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c789c', endColorstr='#68b0e0',GradientType=1 ); /* IE6-9 */
}

@mixin light-header-colors() {
    background: white
}


#sub-head-menu.submenu.portfolio {
    @media screen and (max-width: 650px) {
        display: none;
    }
    @media screen and (max-width: 800px) {
        ul a:first-child {
            display: none;
        }
        ul a li {
            font-size: 1.0rem;
        }
    }

}

#headmenuwrapper .topmenuouter {
    @include light-header-colors();
}

#footerwrapper {
    background-color: #000;
    background: linear-gradient(to bottom, #000, #333);
}

#topmenuwrapper .topmenubar {
    background-color:#333;
}

#footerwrapper .footer-copyright-wrapper {
    background-color: #000;
}

.footer-logo-link {
    padding-right: 20px;
    max-width: 200px;
    .footer-logo {
        width: 100%;
        padding-right: 20px;
        .text-app {
            fill: #eee;
        }
        .text-zuka {
            fill: $appzuka;
        }
    }
}

.header-logo {
    .text-app {
        fill: #333;
        #topmenuwrapper & {
            fill: white;
        }
    }
    .text-zuka {
        fill: $appzuka;
    }
}

// TODO: Default is none, would be better to have default block
h2.hero-image-text {
    display: block;
}

.page-standard {
    p {
        font-size: 1em;
    }
}

#page-contact {
    .contact-wrapper .contact-address-row .contact-details .contact-row {
        align-items: center;
        svg.contact-icon {
            width: 30px;
            @media (min-width: 499px) {
                width: 40px;
            }
        }
    }
}


// Submenu colour white if top menu background is dark
// #sub-head-menu {

//     ul {
//         color: white;
//         a {
//             &, &:visited {
//                 color: white;
//             }
//             &.active-link {
//                 color: $highlight
//               }
//             @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
//               &:hover {
//                 color: $hover
//               }
//             }
//         }
//     }
// }

#bannerwrapper.photobanner {
    position: relative;
    min-height: 66.6vw;
    @media screen and (min-width: 550px) {
        min-height: 33.3vw;
    }
    @media screen and (min-width: 1200px) {
        min-height: 25vw;
    }
    picture {
        line-height: 0;
        position: absolute;
    }
    picture img { // Needed if srcset fails and default image chosen
        width: 100%;
    }
}